@import "~bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
@import "react-datepicker/dist/react-datepicker.css";

* {
  box-sizing: border-box;
}

body {
  background: #f1f1f1 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.custom-switch {
  padding: 0;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: -1px 5px 9px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.mainContent {
  padding: 2rem 0.5rem;
}

.footer {
  background: white;
  padding: 10px;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.form-switch {
  padding-left: 0 !important;
}

.table-row {
  border: 1px solid #ced4da;
  border-radius: 10px;
  position: relative;

  .delete {
    width: auto;
    position: absolute;
    right: -14px;
    top: -10px;
    display: flex;
    background-color: #e1cece;
    border-radius: 50%;
    padding: 7px;

    img {
      height: 20px;
      width: 20px;
    }
  }
}

.navbar-toggler {
  display: none;
}